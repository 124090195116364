<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Customer Name</th>
          <th>Order Date</th>
          <th>Order Number</th>
          <th class="text-right">Order Qty</th>
          <th class="text-right">Delivered Qty</th>
          <th class="text-right">Due Qty</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in purchase" :key="i">
          <td> {{ item.party_name }}</td>
          <td> {{ item.date }}</td>
          <td> {{ item.bill_number }}</td>
          <td class="text-right"> {{ item.total_qty }}</td>
          <td class="text-right"> {{ item.delivered_qty }}</td>
          <td class="text-right"> {{ parseFloat(item.total_qty) - parseFloat(item.delivered_qty) }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="createChallan(item.id)"
                    style="cursor: pointer"
                    v-if="(parseFloat(item.total_qty) - parseFloat(item.delivered_qty)) > 0"
            >
              Create Challan
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!purchase.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";

export default {
  name: 'PurchaseListTable',
  data() {
    return{
      companyInfo: {},
      invoice: {},
      barcodeQuery: '',
      barcode: '',
      user: {},
      qrcode: ''
    }
  },
  props: {
    purchase: Array,
    route_name: {
      type: String,
      default: 'purchase-bill-edit'
    },
  },
  setup() {
    const {commaFormat} = figureFormatter()
    const { fetchSalesBill } = handlePurchase();
    const {fetchCompanyInfo} = handleCompany();
    const { exportToPDF } = pdfPrinter();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();

    return {
      commaFormat,
      fetchSalesBill,
      exportToPDF,
      fetchCompanyInfo,
      fetchBarcode,
      store
    }
  },
  mounted() {
    this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
      this.companyInfo = res.data;
    }).catch((error) => {
      console.log(error);
    });

    this.user = this.store.state.user.user
  },
  methods: {
    createChallan(id) {
      let param = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
      }
      param['salesId'] = id;
      this.$router.push({
        name: this.route_name,
        params: param,
        query: this.$route.query
      })
    },
    formatDate(date) {
      const formattedDate = date.toISOString().split('T')[0];
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      const formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

      return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
    },
    formatToDdMmYy(date) {
      const dat = date.split('-');
      return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
  }
}
</script>
